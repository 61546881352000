<div class="row background-color">
    <div class="col-md-3 image-container">
        <div class="small-image-container">
            <img id="trash" src="../../../assets/images/remove-site/trash.png" />
            <h5 class="sites-header">{{ 'remove-site.header' | translate }}</h5>
            <h6 class="sites-sub-header">{{ 'remove-site.sub-header' | translate }}</h6>
        </div>
    </div>
    <div class="col-md-9 text-center tidy-margin">
        <h1 class="sites-header">{{ 'remove-site.content-header' | translate }}</h1>
        <div class="col-md-10 text-center mx-auto makeBorder">
            <div class="justify-content-center align-items-center form-div">
                <form [formGroup]="removeSiteForm" (ngSubmit)="onSubmit(removeSiteForm.value)">
                    <div class="col-md-10 mx-auto">
                        <h2 class="form-header">{{ 'form.header' | translate }}</h2>
                        <h6 class="form-sub-header">{{ 'form.sub-header' | translate }}
                            <br /><br />
                            {{ 'remove-site.form.sub-header' | translate }}
                        </h6>
                        <div class="form-group">
                            <div>
                                <input type="text" formControlName="username" class="form-control" id="username"
                                    placeholder="*{{ 'form.username' | translate }}"
                                    [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
                                    [ngModel]="displayUserName">
                                <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                                    <div *ngIf="f.username.errors.required">{{ 'form.validation.emptyUsername'
                                        |translate}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group after-div">
                            <div>
                                <input type="text" formControlName="url" class="form-control" id="url"
                                    placeholder="*{{ 'form.site-address' | translate }}"
                                    [ngClass]="{ 'is-invalid': submitted && f.url.errors }">
                                <div *ngIf="submitted && f.url.errors" class="invalid-feedback">
                                    <div *ngIf="f.url.errors.required">{{ 'form.validation.emptySiteaddress' | translate
                                        }}
                                    </div>
                                </div>
                                <div *ngIf="submitted && f.url.errors" class="invalid-feedback">
                                    <div *ngIf="f.url.errors.pattern"> {{ 'form.validation.invalidUrl' |
                                        translate }} </div>
                                </div>
                            </div>
                        </div>
                        <div class="pt-3 pb-3 remove-more">
                            <div>{{ 'remove-site.form.remove-more-header' | translate }}
                            </div>
                        </div>
                        <div>
                            <button [disabled]="!enableSubmit" type="submit" class="btn-nativ form-btn">{{ 'global.send'
                                |
                                translate }}
                                &#10095;&#10095;</button>
                        </div>
                        <div class="form-group">
                            <div>
                                <input type="text" formControlName="url1" class="form-control" id="url1"
                                    placeholder="{{ 'form.site-address' | translate }}">
                            </div>
                        </div>
                        <div class="form-group">
                            <div>
                                <input type="text" formControlName="url2" class="form-control" id="url2"
                                    placeholder="{{ 'form.site-address' | translate }}">
                            </div>
                        </div>
                        <div class="form-group">
                            <div>
                                <input type="text" formControlName="url3" class="form-control" id="url3"
                                    placeholder="{{ 'form.site-address' | translate }}">
                            </div>
                        </div>
                        <div class="form-group">
                            <div>
                                <input type="text" formControlName="url4" class="form-control" id="url4"
                                    placeholder="{{ 'form.site-address' | translate }}">
                            </div>
                        </div>
                        <button [disabled]="!enableSubmit" type="submit" class="btn-nativ form-btn">{{ 'global.send-all'
                            |translate }}
                            &#10095;&#10095;</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<app-bottom-nav></app-bottom-nav>