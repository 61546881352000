import { Injectable } from '@angular/core';
import { Subject, Observable } from "rxjs";
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SalesService {
  updateSteps$: Observable<any>;
  private steps = new Subject<any>();

  getStepsData$: Observable<any>;
  private stepsData = new Subject<any>();
  saleDetails = { salesId: "" }
  stepByStep = ["start"]
  salesUserId = 45604 //43784
  constructor(private httpClient: HttpClient) {
    this.updateSteps$ = this.steps.asObservable();
    this.getStepsData$ = this.stepsData.asObservable();
  }

  nextStep(step) {
    console.log("nextStep: " + step);
    this.steps.next(step);
  }
  updateStepData(data) {
    console.log("updateStepData: " + data.nextStep);
    this.stepByStep.push(data.nextStep)
    this.stepsData.next(data);
  }
  public sendBuyDetails(data,type="new") {
    data.type=type
    return this.httpClient.post('/api/sales/send_buy_details',
      JSON.stringify({ data }), {
      headers: { 'Content-Type': 'application/json' }
    })
  }
  public checkUsername(username) {
    return this.httpClient.get(`api/sales/check_username`,
      { headers: { 'Content-Type': 'application/json' }, params: { username } })
  }

  public saveDetails(data) {
    return this.httpClient.post('/api/sales/save_sale_details',
      JSON.stringify({ data }), {
      headers: { 'Content-Type': 'application/json' }
    })
  }

  public getCities() {
    return this.httpClient.get(`/api/sales/get_cities`,
      { headers: { 'Content-Type': 'application/json' } })
  }

  public getStreets(city) {
    return this.httpClient.get(`/api/sales/get_cities`,
      { headers: { 'Content-Type': 'application/json' }, params: { city } })
  }

  public getCommunities() {
    return this.httpClient.get(`/api/sales/get_communities`,
      { headers: { 'Content-Type': 'application/json' } })
  }

  public getPayType(login) {
    return this.httpClient.post('/api/sales/get_pay_type',
      JSON.stringify({ login }), {
      headers: { 'Content-Type': 'application/json' }
    })
  }
  public getProfessions() {
    return this.httpClient.get(`/api/sales/get_professions`,
      { headers: { 'Content-Type': 'application/json' } })
  }

  public verifyUser(confirmationCode) {
    return this.httpClient.get(`api/sales/confirm`,
      { headers: { 'Content-Type': 'application/json' }, params: { confirmationCode } })
  }

  public checkCupon(cuponName) {
    return this.httpClient.get(`api/sales/check_cupon`,
      { headers: { 'Content-Type': 'application/json' }, params: { name: cuponName } })
  }

  public tranzilaCardValidation(cardDetails) {
    return this.httpClient.post('/api/sales/card_Validation',
      JSON.stringify({ cardDetails }), {
      headers: { 'Content-Type': 'application/json' }
    })
  }
}
