import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../authentication/authentication.service'
import { UtilsService } from '../utils/utils.service'
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  pendingMsgsCount: number;
  pendingMsgsCountChange: Subject<number> = new Subject<number>();
  categories$: Subject<any> = new Subject<[]>();
  protocol = window.location.protocol;
  host = window.location.host;
  srcCategories = []
  codeSent$: BehaviorSubject<string> = new BehaviorSubject("");
  pendingDocsData = []
  updatePendingDocs$: Observable<any>;
  private pendingDocs = new Subject<any>();

  constructor(private httpClient: HttpClient,
    private authenticationService: AuthenticationService,
    private titleService: Title,
    private translateService: TranslateService,
    private utilsService: UtilsService) {
    this.pendingMsgsCount = 0;
    this.updatePendingDocs$ = this.pendingDocs.asObservable();
  }

  updatePendingDocs(docs) {
    console.log("updatePendingDocs: " + docs);
    this.pendingDocsData = docs;
    this.pendingDocs.next(docs);
  }

  public getCurrencies() {
    return this.httpClient.get(`/exchangeRates.json`,
      { headers: { 'Content-Type': 'application/json' } })
  }

  public getStations() {
    return this.httpClient.get(`api/get_weather_stations`,
      { headers: { 'Content-Type': 'application/json' } })
  }

  public getWeather(stId) {
    return this.httpClient.get(`api/get_weather`,
      { headers: { 'Content-Type': 'application/json' }, params: { stId: stId } })
  }

  public login(loginData: any) {
    return this.httpClient.get(`api/login`,
      { headers: { 'Content-Type': 'application/json' }, params: { login: loginData.username, pass: loginData.password } })
    // return this.httpClient.get(`${this.protocol}//api.enativ.com/ApiSite/GetDataBylogin.aspx?login=${loginData.username}&pass=${loginData.password}`)
  }

  public getDataInInitAspxPage() {
    return this.httpClient.get(`init.aspx`, { responseType: 'text' })
  }

  public checkCrt() {
    return this.httpClient.get(`${this.protocol}//filtercert.enativ.com/`,
      { headers: { 'Content-Type': 'application/json' } })
  }

  public techLogin(loginData: any) {
    return this.httpClient.get(`api/tech_login`,
      { headers: { 'Content-Type': 'application/json' }, params: { login: loginData.username, phonenumber: loginData.phonenumber } })
  }

  public netsparkLogin(userid) {
    return this.httpClient.get(`api/login`,
      { headers: { 'Content-Type': 'application/json' }, params: { login: '', guid: userid } })
    // return this.httpClient.get(`${this.protocol}//api.enativ.com/ApiSite/GetDataBylogin.aspx?guid=${userid}&login=''`)
  }

  public probDocLogin(userid) {
    return this.httpClient.get(`api/login`,
      { headers: { 'Content-Type': 'application/json' }, params: { login: '', useridp: userid } })
    // return this.httpClient.get(`${this.protocol}//api.enativ.com/ApiSite/GetDataBylogin.aspx?useridp=${userid}&login=''`)
  }

  public contact(content, dept, login, phone, mail, fname, lname, priority, adminid = null) {
    //`Content=${formData.content}&Dept=${this.depNumber}&Login=${login}&tel=${formData.phonenumber}&mail=${formData.email}&fname=${fname}&lname=${lname}`
    console.log('from contanct post')
    let windowsUsers, windowsMachine, nativVersion
    const userData = this.authenticationService.userData
    if (userData) {
      windowsUsers = userData.WindowsUsers ? (Object.keys(userData.WindowsUsers)[0] + "-" + Object.values(userData.WindowsUsers)[0]) : ""
      windowsMachine = userData.WindowsMachine ? userData.WindowsMachine : ""
      nativVersion = userData.NativVersion ? userData.NativVersion : ""
    }

    const formData = new FormData();

    formData.append('Content', content);
    formData.append('Dept', dept);
    formData.append('Login', login);
    formData.append('tel', phone);
    formData.append('mail', mail);
    formData.append('fname', fname);
    formData.append('lname', lname);
    formData.append('priority', priority);
    formData.append('windowsUsers', windowsUsers);
    formData.append('windowsMachine', windowsMachine);
    formData.append('nativVersion', nativVersion);
    formData.append('KEY', 'irufyd98yrkjdfng95530kdmfdklsj');

    if (adminid !== null) {
      formData.append('adminid', adminid);
    }
    let server = "api.enativ.com"
    if (this.host.indexOf("devsite") > -1 || this.host.indexOf("localhost") > -1) {
      server = "dev-api.enativ.com" // "localhost:63982"
    }
    return this.httpClient.post(`${this.protocol}//${server}/AddNewProspect.aspx`, formData)
  }

  public getHebrewDate() {
    return this.httpClient.get(`api/get_heb_date`,
      { headers: { 'Content-Type': 'application/json' } })
  }

  public getCategories(lang) {
    return this.httpClient.get(`api/get_categories`,
      { headers: { 'Content-Type': 'application/json' }, params: { userId: this.authenticationService.userData.id, lang: lang } })
  }
  public getSites(id, name) {
    return this.httpClient.get(`api/get_sites`,
      { headers: { 'Content-Type': 'application/json' }, params: { userId: this.authenticationService.userData.id, catId: id, catName: name, lang: this.translateService.currentLang.toUpperCase() } })
  }
  public searchSites(search) {
    return this.httpClient.get(`api/search_sites`,
      { headers: { 'Content-Type': 'application/json' }, params: { userId: this.authenticationService.userData.id, search: search, lang: this.translateService.currentLang.toUpperCase() } })
  }
  // public getUserSites(index,sorted, filter) {
  //   return this.httpClient.get(`api/get_user_sites`,
  //     { headers: { 'Content-Type': 'application/json' }, params: { userId: this.authenticationService.userData.id, index: index,sorted,filter, lang: this.translateService.currentLang.toUpperCase() } })
  // }
  // public getUserSites(index, sorted, filter) {
  //   return this.httpClient.get(`api/get_user_sites`, {
  //     headers: {
  //       'Content-Type': 'application/json'
  //     },
  //     params: {
  //       userId: this.authenticationService.userData.id,
  //       index: index,
  //       sorted: sorted,
  //       ...filter,
  //       lang: this.translateService.currentLang.toUpperCase()
  //     }
  //   });
  // }
  public getComputerName(id = [], isFiltering = "false") {
    return this.httpClient.get(`api/get_computer_name`, {
      headers: {
        'Content-Type': 'application/json'
      },
      params: {
        userId: id?.join(','),
        managerId: this.authenticationService.userData.id,
        isFiltering: isFiltering
      }
    });
  }

  public getSiteName(windowsMachine = [], windowsUsers = []) {
    return this.httpClient.get(`api/get_site_name`, {
      headers: {
        'Content-Type': 'application/json'
      },
      params: {
        userId: this.authenticationService.userData.id,
        windowsMachine: windowsMachine?.join(','),
        windowsUsers: windowsUsers?.join(',')
      }
    });
  }
  public getUserSites(index, sorted, status, filterKey, filterValue, isExpanded = "", filterKeyBasic = "", filterValueBasic = []) {
    return this.httpClient.get(`api/get_user_sites`, {
      headers: {
        'Content-Type': 'application/json'
      },
      params: {
        userId: this.authenticationService.userData.id,
        index: index,
        sorted: sorted,
        status: status?.join(','),
        lang: this.translateService.currentLang.toUpperCase(),
        filterKey: filterKey,
        filterValue: filterValue?.join(','),
        filterKeyBasic: filterKeyBasic,
        filterValueBasic: filterValueBasic?.join(','),
        isExpanded: isExpanded,
        login: this.authenticationService.userData.login
      }
    });
  }

  public getUserSitesCount(status, filterKey, filterValue, isExpanded = "") {
    return this.httpClient.get(`api/get_user_sites_count`, {
      headers: {
        'Content-Type': 'application/json'
      },
      params: {
        userId: this.authenticationService.userData.id,
        filterKey: filterKey,
        filterValue: filterValue?.join(','),
        lang: this.translateService.currentLang.toUpperCase(),
        status: status?.join(','),
        isExpanded: isExpanded
      }
    })
  }

  public getUserData(username, userId = "") {
    const key = "147258369" + this.utilsService.stringToHex(username)
    let id = this.authenticationService.userData ? this.authenticationService.userData.id : userId;
    return this.httpClient.get(`api/get_user_data`,
      { headers: { 'Content-Type': 'application/json' }, params: { username: username, key, managerId: id } })
  }
  public getSerialsData(username) {
    const key = "147258369" + this.utilsService.stringToHex(username)
    // const url = "https://www.enativ.com/api/get_serials_data?username=topgroup-t&key=1472583690074006f007000670072006f00750070002d0074"
    return this.httpClient.get(`api/get_serials_data`,
      { headers: { 'Content-Type': 'application/json' }, params: { username: username, key } })
  }

  public removeUserSite(siteId) {
    return this.httpClient.get(`api/remove_user_site`,
      { headers: { 'Content-Type': 'application/json' }, params: { userId: this.authenticationService.userData.id, siteId: siteId } })
  }

  public refreshNativ() {
    return this.httpClient.get(`${this.protocol}//${this.host}/refresh`, { responseType: 'text' })
  }
  public removeUserRequest(url) {
    return this.httpClient.get(`api/remove_user_request`,
      { headers: { 'Content-Type': 'application/json' }, params: { userId: this.authenticationService.userData.id, url: url } })
  }
  public addSite(data, reqData) {
    return this.httpClient.get(`api/add_site`,
      { headers: { 'Content-Type': 'application/json' }, params: { username: data.username, sites: data.sites, email: data.email, reqData } })
  }

  public getLicDataByIp(ipAddress) {
    return this.httpClient.get(`api/get_lic_data_by_ip`,
      { headers: { 'Content-Type': 'application/json' }, params: { ipAddress } })
  }
  public removeSite(data) {
    return this.httpClient.get(`api/remove_site`,
      { headers: { 'Content-Type': 'application/json' }, params: { userId: this.authenticationService.userData.id, sites: data.sites } })
  }
  public getUserMails(index) {
    return this.httpClient.get(`api/get_user_mails`,
      { headers: { 'Content-Type': 'application/json' }, params: { userId: this.authenticationService.userData.id, mailAddress: this.authenticationService.userData.email.split(",")[0], index: index } })
  }
  public clearMailsCount() {
    return this.httpClient.get(`api/clear_mails_count`,
      { headers: { 'Content-Type': 'application/json' }, params: { userId: this.authenticationService.userData.id } })
  }
  public getPendingDocs(userId = "") {
    return this.httpClient.get(`api/get_pending_docs`,
      { headers: { 'Content-Type': 'application/json' }, params: { userId: userId !== "" ? userId : this.authenticationService.userData.id } })
  }

  public closeDoc(docId, formdata = {}) {
    return this.httpClient.get(`api/close_doc`,
      { headers: { 'Content-Type': 'application/json' }, params: { userId: this.authenticationService.userData?.id ? this.authenticationService.userData.id : formdata["userid"], docId, name: formdata ? formdata["name"] : "", typeDoc: formdata ? formdata["typeDoc"] : "" } })
  }

  public forgotPass(data) {
    return this.httpClient.get(`api/forgot_pass`,
      { headers: { 'Content-Type': 'application/json' }, params: { userName: data.username } })
  }

  public updateUserEmail(email) {
    return this.httpClient.post('/api/update_user_email', JSON.stringify({ userId: this.authenticationService.userData.id, email }), {
      headers: { 'Content-Type': 'application/json' }
    })
  }
  public addYoutubeProfile(data) {
    data.userId = this.authenticationService.userData.id
    return this.httpClient.post('/api/add_youtube_profile', JSON.stringify(data), {
      headers: { 'Content-Type': 'application/json' }
    })
  }

  public addScheduling(data) {
    data.userId = this.authenticationService.userData.id
    return this.httpClient.post('/api/add_scheduling', JSON.stringify(data), {
      headers: { 'Content-Type': 'application/json' }
    })
  }

  public getUserScheduling() {
    const userId = this.authenticationService.userData.id
    return this.httpClient.get(`api/get_user_scheduling`,
      {
        headers: { 'Content-Type': 'application/json' },
        params:
          { userId }
      })
  }

  public updateProcpect(pid, dpt, content) {
    return this.httpClient.post('/api/update_procpect', JSON.stringify({ pid, dpt, content }), {
      headers: { 'Content-Type': 'application/json' }
    })
  }

  public checkPhoneCallid(phonecallid) {
    return this.httpClient.post('/api/check_phonecallid', JSON.stringify({ phonecallid }), {
      headers: { 'Content-Type': 'application/json' }
    })
  }

  public saveFeedback(q1, q2, q3, comments, phonecallid, type) {
    return this.httpClient.post('/api/save_feedback', JSON.stringify({ q1, q2, q3, comments, phonecallid, type }), {
      headers: { 'Content-Type': 'application/json' }
    })
  }

  public addFeedbackChat(userId, q1, q2, q3, freeText, type) {
    return this.httpClient.post('/api/add_feedback_chat', JSON.stringify({ userId, q1, q2, q3, freeText, type }), {
      headers: { 'Content-Type': 'application/json' }
    })
  }
  public getSupportDocs() {
    return this.httpClient.get(`api/get_support_docs`,
      { headers: { 'Content-Type': 'application/json' } })
  }
  public sendUnusualSiteApprovalDoc(data) {
    return this.httpClient.get(`api/send_unusual_site_approval_doc`,
      {
        headers: { 'Content-Type': 'application/json' },
        params:
          { userId: this.authenticationService.userData.id, userName: data.username, password: data.password, idNum: data.idNum ? data.idNum : "", cardDigits: data.cardDigits ? data.cardDigits : "", hoursNum: data.hoursNum, reason: data.reason, docId: data.docId, url: data.url, telcode: data.telcode ? data.telcode : "0", ccEmail: data.ccEmail ? data.ccEmail : "" }
      })
  }
  public sendTempRemovalDoc(data) {
    return this.httpClient.get(`api/send_temp_removal_doc`,
      {
        headers: { 'Content-Type': 'application/json' },
        params:
          { userId: this.authenticationService.userData.id, userName: data.username, password: data.password, idNum: data.idNum ? data.idNum : "", cardDigits: data.cardDigits ? data.cardDigits : "", reason: data.reason, docId: data.docId, telcode: data.telcode ? data.telcode : "0" }
      })
  }
  public sendCloseSubscriptionDoc(data) {
    return this.httpClient.get(`api/send_close_subscription_doc`,
      {
        headers: { 'Content-Type': 'application/json' },
        params:
          { userId: this.authenticationService.userData ? this.authenticationService.userData.id : 0, userName: data.username, password: data.password, idNum: data.idNum ? data.idNum : "", cardDigits: data.cardDigits ? data.cardDigits : "", reason: data.reason, docId: data.docId, telcode: data.telcode ? data.telcode : "0" }
      })
  }
  public sendTempOpenDoc(data) {
    return this.httpClient.get(`api/send_temp_open_doc`,
      {
        headers: { 'Content-Type': 'application/json' },
        params:
          { userId: this.authenticationService.userData.id, userName: data.username, password: data.password, idNum: data.idNum ? data.idNum : "", cardDigits: data.cardDigits ? data.cardDigits : "", reason: data.reason, docId: data.docId, telcode: data.telcode ? data.telcode : "0" }
      })
  }
  public sendToGrayListDoc(data) {
    return this.httpClient.get(`api/send_to_gray_list_doc`,
      {
        headers: { 'Content-Type': 'application/json' },
        params:
          { userId: this.authenticationService.userData.id, userName: data.username, password: data.password, idNum: data.idNum ? data.idNum : "", cardDigits: data.cardDigits ? data.cardDigits : "", docId: data.docId, telcode: data.telcode ? data.telcode : "0" }
      })
  }
  public sendProbSiteApprovDoc(data) {
    return this.httpClient.get(`api/send_prob_site_approv_doc`,
      {
        headers: { 'Content-Type': 'application/json' },
        params:
          { userId: data.userId, pass: data.pass, sd: data.sd, password: data.password, addSitesCode: data.passwordAdd, reason: data.reason, code: data.code ? data.code : "", hoursNum: data.hoursNum, timeType: data.timeType, ravName: data.ravName, telcode: data.telcode ? data.telcode : "0", ccEmail: data.ccEmail ? data.ccEmail : "" }
      })
  }


  public sendTermsOfUseApproval(data) {
    return this.httpClient.get(`api/send_terms_of_use_approval`,
      {
        headers: { 'Content-Type': 'application/json' },
        params:
          { userId: data.userId }
      })
  }

  public getUserMailsCount(read) {
    return this.httpClient.get(`api/get_user_mails_count`,
      { headers: { 'Content-Type': 'application/json' }, params: { userId: this.authenticationService.userData.id, mailAddress: this.authenticationService.userData.email.split(",")[0], read: read } })
  }

  public updateDeleteEmailApprove(userId) {
    return this.httpClient.post('/api/update_delete_email_approve', JSON.stringify({ userId: userId }), {
      headers: { 'Content-Type': 'application/json' }
    })
  }
  public updateUserMailsCount() {
    this.getUserMailsCount(true).subscribe(
      data => {
        console.log('success getUserMailsCount', data)
        if (data["d"] != null) {
          this.pendingMsgsCount = data["d"]
          this.pendingMsgsCountChange.next(this.pendingMsgsCount);
          if (this.pendingMsgsCount > 0) {
            // this.titleService.setTitle("Nativ (" + (this.pendingMsgsCount.toString()) + ")");
          }
          else {
            this.titleService.setTitle("Nativ");
          }
        }
      },
      error => {
        console.log('error getUserMailsCount', error)
      }
    );
  }

  public getOmer(data) {
    return this.httpClient.get(`api/get_omer`,
      {
        headers: { 'Content-Type': 'application/json' },
        params:
          { addDay: data.addDay == true ? "1" : "0" }
      })
  }
  public getKosherZmanim(date, lat, long, altitude) {
    return this.httpClient.get(`api/get_zmanim`,
      {
        headers: { 'Content-Type': 'application/json' },
        params:
          { date, lat, long, altitude }
      })
  }
  public isPilotUser(userId) {
    return this.httpClient.get(`api/is_pilot_user`,
      {
        headers: { 'Content-Type': 'application/json' },
        params:
          { userId }
      })
  }


  initCategories(lang?) {
    lang = lang == undefined ? this.translateService.currentLang : lang
    this.getCategories(lang.toUpperCase()).subscribe(
      data => {
        console.log('initCategories ', data["d"])
        this.srcCategories = data["d"]
        this.categories$.next(this.devideCategories());
      },
      error => console.log('error initCategories', error)
    )
  }
  devideCategories() {
    const data = this.srcCategories;
    const arr1 = []
    const arr2 = []
    for (let i = 0; i < data.length; i += 2) {
      const obj = {}
      if (i < data.length) {
        obj["id1"] = data[i].id
        obj["name1"] = data[i].name
      }
      if (i + 1 < data.length) {
        obj["id2"] = data[i + 1].id
        obj["name2"] = data[i + 1].name
      }
      arr2.push(obj)
    };

    for (let i = 0; i < data.length; i += 4) {
      const obj = {}
      if (i < data.length) {
        obj["id1"] = data[i].id
        obj["name1"] = data[i].name
      }
      if (i + 1 < data.length) {
        obj["id2"] = data[i + 1].id
        obj["name2"] = data[i + 1].name
      }
      if (i + 2 < data.length) {
        obj["id3"] = data[i + 2].id
        obj["name3"] = data[i + 2].name
      }
      if (i + 3 < data.length) {
        obj["id4"] = data[i + 3].id
        obj["name4"] = data[i + 3].name
      }
      arr1.push(obj)
    };
    return { categories: arr1, mCategories: arr2 };
  }
  sendUserParams(data) {
    return this.httpClient.get(`api/send_user_params`,
      {
        headers: { 'Content-Type': 'application/json' },
        params: data,
        responseType: 'text'
      })
  }

  inactiveUser(data) {
    data = { ...data }
    data.UserID = this.authenticationService.userData.id.toString();
    return this.httpClient.get(`api/inactive_user`,
      {
        headers: { 'Content-Type': 'application/json' },
        params: data,
        responseType: 'text'
      })
  }

  knTemplate(data) {
    data = { ...data }
    data.UserID = this.authenticationService.userData.id.toString();
    return this.httpClient.get(`api/kn_template`,
      {
        headers: { 'Content-Type': 'application/json' },
        params: data,
        responseType: 'text'
      })
  }
  GetInfo(data) {
    data = { ...data }
    return this.httpClient.get(`api/get_info`,
      {
        headers: { 'Content-Type': 'application/json' },
        params: data,
        responseType: 'text'
      })
  }

  uploadImage(image) {
    const formData = new FormData();

    formData.append('uploadedImage', image);

    return this.httpClient.post('/api/site_upload_image', formData);
  }

  uploadExistsImage(image) {
    return this.httpClient.post('/api/site_upload_exists_image',
      JSON.stringify({ userId: this.authenticationService.userData.id, uploadedImage: image }), {
      headers: { 'Content-Type': 'application/json' }
    })
  }

  uploadImage1366(image) {
    const formData = new FormData();

    formData.append('uploadedImage', image);

    return this.httpClient.post('/api/upload_image1366', formData);
  }

  getAlert() {
    return this.httpClient.get(`api/get_alert`,
      {
        headers: { 'Content-Type': 'application/json' },
        responseType: 'text'
      })
  }
  getBgImages() {
    return this.httpClient.get(`api/get_bg_images`,
      {
        headers: { 'Content-Type': 'application/json' },
        responseType: 'text'
      })
  }
  saveAlert(alert) {
    return this.httpClient.get(`api/save_alert`,
      {
        headers: { 'Content-Type': 'application/json' },
        responseType: 'text',
        params:
          { alert, userId: this.authenticationService.userData.id }
      })
  }

  public hasSimActionsPermmissions(userId) {
    return this.httpClient.get(`api/sim_ations_permmissions`,
      {
        headers: { 'Content-Type': 'application/json' },
        params:
          { userId }
      })
  }

  public checkUserEmail() {
    return this.httpClient.get(`api/has_enativ_or_kavnaki_email`,
      {
        headers: { 'Content-Type': 'application/json' },
        params:
          { userId: this.authenticationService.userData.id }
      })
  }
  public syncSim(userId, ntlogin) {
    return this.httpClient.get(`api/sync_sim`,
      {
        headers: { 'Content-Type': 'application/json' },
        params:
          { userId, ntlogin }
      })
  }
  public getHelpmeCode(data) {
    return this.httpClient.get(`api/get_helpme_code`,
      {
        headers: { 'Content-Type': 'application/json' },
        params: data
      })
  }
  public techAction(type, data) {
    return this.httpClient.post('/api/tech_action',
      JSON.stringify({ userId: this.authenticationService.techUserData.id, data, type }), {
      headers: { 'Content-Type': 'application/json' }
    })
  }

  public syncSiteForUser(userId, siteId) {
    return this.httpClient.get(`api/sync_site`,
      {
        headers: { 'Content-Type': 'application/json' },
        params:
          { userId, siteId }
      })
  }

  public getCompanyPackages() {
    return this.httpClient.get(`api/get_company_packages`,
      {
        headers: { 'Content-Type': 'application/json' },
      })
  }
  public createSim(userId, simPackage, simCompany, serialNum, simNum) {
    return this.httpClient.get(`api/create_sim`,
      {
        headers: { 'Content-Type': 'application/json' },
        params:
          { userId, simPackage, simCompany, serialNum, simNum }
      })
  }
  public deleteSim(userId, ntlogin) {
    return this.httpClient.get(`api/delete_sim`,
      {
        headers: { 'Content-Type': 'application/json' },
        params:
          { userId, ntlogin }
      })
  }
  public updateVerifySendingAttempts(data) {
    this.httpClient.post('/api/update_verify_sending_attempts',
      JSON.stringify({ userId: data.reason == "כניסת טכנאי" && this.authenticationService.techUserData ? this.authenticationService.techUserData.id : this.authenticationService.userData.id, userCode: data.userCode, sentCode: data.sentCode, isValid: data.isValid, times: data.times, reason: data.reason }), {
      headers: { 'Content-Type': 'application/json' }
    }).subscribe(
      data => {
        console.log('success updateVerifySendingAttempts', data)
      },
      error => {
        console.log('error updateVerifySendingAttempts', error)
      }
    );
  }


  public sendVerifyCode(type, reason, phoneNumber = "") {
    return this.httpClient.get(`api/send_verify_code`,
      {
        headers: { 'Content-Type': 'application/json' },
        params:
          { userId: reason == "כניסת טכנאי" && this.authenticationService.techUserData ? this.authenticationService.techUserData.id : this.authenticationService.userData.id, type, reason, key: (new Date().getTime() * 167).toString(), phoneNumber }
      })
  }

  public isGTPTAlert() {
    return this.httpClient.get(`api/is_gpt_alert`,
      {
        headers: { 'Content-Type': 'application/json' },
        params:
          { userId: this.authenticationService.userData.id }
      })
  }

  public getLastNativVersion() {
    return this.httpClient.get(`api/get_last_nativ_version`,
      {
        headers: { 'Content-Type': 'application/json' },

      })
  }

  public addPhoneCall(userId, comments) {
    return this.httpClient.get(`api/add_phone_call`,
      {
        headers: { 'Content-Type': 'application/json' },
        params: { userId: userId, comments: comments }
      })
  }

  updateUser(userId: number, updateData: any, updateCB: any): void {
    this.httpClient.post('UserService.asmx/UpdateUser', {
      headers: { 'Content-Type': 'application/json' },
      params: { adminId: 46693, userId: userId, updateData: updateData }
    })
      .subscribe(
        response => updateCB(response),
        error => {
          const errorResponse = { error: error.statusText };
          console.log(errorResponse)
          updateCB(errorResponse);
        }
      );
  }

  public existEmployees() {
    return this.httpClient.get(`api/get_exist_employees`,
      {
        headers: { 'Content-Type': 'application/json' },
      })
  }

  public existSitesEmployees() {
    return this.httpClient.get(`api/get_exist_sites_employees`,
      {
        headers: { 'Content-Type': 'application/json' },
      })
  }

  public isPendingSite(userId, url) {
    return this.httpClient.post(`api/get_pending_site`,
      {
        headers: { 'Content-Type': 'application/json' },
        params: { userId: userId, url: url }
      })
  }

  public setPrioritySite(userId) {
    return this.httpClient.post(`api/get_wait_sites`,
      {
        headers: { 'Content-Type': 'application/json' },
        params: { userId: userId }
      })
  }

  public getActionCount(userId, actionId) {
    return this.httpClient.post(`api/get_action_count`,
      {
        headers: { 'Content-Type': 'application/json' },
        params: { userId: userId, actionId: actionId }
      })
  }

  public getWaitSites(userId) {
    return this.httpClient.post(`api/get_wait_sites_for_user`,
      {
        headers: { 'Content-Type': 'application/json' },
        params: { userId: userId }
      })
  }

  public saveChatOpen(msg, userId, time) {
    return this.httpClient.post(`api/save_chat_open`,
      {
        headers: { 'Content-Type': 'application/json' },
        params: { msg: msg, userId: userId, timeString: time }
      })
  }

  public saveChatMsg(msg, chatId, whoSend, fullCurrentTime) {
    return this.httpClient.post(`api/save_chat_msg`,
      {
        headers: { 'Content-Type': 'application/json' },
        params: { msg: msg, chatId: chatId, whoSend: whoSend, timeString: fullCurrentTime }
      })
  }

  public hasUserSite(userId, url) {
    return this.httpClient.post(`api/has_user_site`,
      {
        headers: { 'Content-Type': 'application/json' },
        params: { userId: userId, url: url }
      })
  }

  public hasUserWaitSite(userId, url) {
    return this.httpClient.post(`api/has_user_wait_site`,
      {
        headers: { 'Content-Type': 'application/json' },
        params: { userId: userId, url: url }
      })
  }

  public howManySitesWait() {
    return this.httpClient.get(`api/how_many_sites_wait`,
      {
        headers: { 'Content-Type': 'application/json' },
      })
  }

  public resetLicence(userId, computerName) {
    return this.httpClient.post(`api/reset_licence`,
      {
        headers: { 'Content-Type': 'application/json' },
        params: { userId: userId, computerName: computerName }
      })
  }

  public resetSomeLicence(userId) {
    return this.httpClient.post(`api/reset_some_licence`,
      {
        headers: { 'Content-Type': 'application/json' },
        params: { userId: userId }
      })
  }

  checkValidDoc(docId, userId = "") {
    const promise = new Promise<boolean>((resolve, reject) => {
      if (docId && !docId.startsWith("time-")) {
        if (this.pendingDocsData.length) {
          if (!this.pendingDocsData.some(el => el.docId == parseInt(docId))) {
            resolve(false)
          }
          else {
            resolve(true)
          }
        }
        else {
          this.getPendingDocs(userId).subscribe(
            data => {
              console.log('success getPendingDocs', data)
              const pendingDocs = data["d"];
              this.updatePendingDocs(pendingDocs);
              if (!pendingDocs.some(el => el.docId == parseInt(docId))) {
                resolve(false)
              }
              else {
                resolve(true)
              }
            },
            error => console.log('error getPendingDocs', error)
          )
        }
      }
      else {
        resolve(true)
      }
    })
    return promise;
  }

  public getChatsRecords(filterChat, page) {
    if (filterChat.length == 0)
      filterChat = ''
    else
      filterChat = JSON.stringify(filterChat)
    return this.httpClient.get(`admin/get_chats_records`, {
      headers: {
        'Content-Type': 'application/json'
      },
      params: {
        filterChat: filterChat,
        page: page
      }
    });
  }

  public getChatsRecordsLists() {
    return this.httpClient.get(`admin/get_chats_records_lists`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  public getChatDetails(id) {
    return this.httpClient.get(`admin/get_chat_details`, {
      headers: {
        'Content-Type': 'application/json'
      },
      params: {
        chatId: id
      }
    });
  }

  public getChatAdmin(filterKey = '', filterValue = '') {
    return this.httpClient.get(`admin/get_chat_admin`, {
      headers: {
        'Content-Type': 'application/json'
      },
      params: {
        filterKey: filterKey,
        filterValue: filterValue
      }
    });
  }

  public getEmployeesLogin(page = "1") {
    return this.httpClient.get(`admin/get_employees_login`, {
      headers: {
        'Content-Type': 'application/json'
      },
      params: {
        page: page
      }
    });
  }

  public saveChatEmployee(adminId, chatId, dept = []) {
    return this.httpClient.post('admin/save_chat_employee', JSON.stringify({ adminId: adminId, chatId: chatId, dept: dept?.join(','), }), {
      headers: { 'Content-Type': 'application/json' }
    })
  }

  public setChatEmployee(adminId, chatId, dept) {
    return this.httpClient.post('admin/set_chat_employee', JSON.stringify({ adminId: adminId, chatId: chatId, dept: dept }), {
      headers: { 'Content-Type': 'application/json' }
    })
  }

  public removeChatEmployee(adminId) {
    return this.httpClient.get(`admin/remove_chat_employee`,
      {
        headers: { 'Content-Type': 'application/json' },
        params:
          { adminId: adminId }
      })
  }

  public getChatProjects() {
    return this.httpClient.get(`admin/get_chat_projects`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  public setProjectStatus(dept, all = 'false') {
    return this.httpClient.post('admin/set_project_status', JSON.stringify({ projectId: dept?.projectId, status: dept?.status, all: all }), {
      headers: { 'Content-Type': 'application/json' }
    })
  }

  public getMailsContent() {
    return this.httpClient.get(`admin/get_mails_content`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  public getMailDetails(id) {
    return this.httpClient.get(`admin/get_mail_details`, {
      headers: {
        'Content-Type': 'application/json'
      },
      params: {
        id: id
      }
    });
  }

  public setEmailContent(mailData) {
    return this.httpClient.post('admin/set_email_content', mailData, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  public saveAdvActions(data) {
    data.userId = this.authenticationService.userData.id
    return this.httpClient.post('api/save_adv_actions', JSON.stringify(data), {
      headers: { 'Content-Type': 'application/json' }
    });
  }
  public addLicenses(login, numOfLicenses) {
    return this.httpClient.post('/api/add_licenses', JSON.stringify({ login: login, numOfLicenses: numOfLicenses }), {
      headers: { 'Content-Type': 'application/json' }
    })
  }

  public updateUserScheduling(data) {
    return this.httpClient.post('api/update_user_scheduling', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }
}






